import { atom } from 'jotai';
const INITIAL_INTRO_VALUE: any = {};

const INITIAL_CONTENT_VALUE: string = '';

// NEW STUFF
export const isStreamingMapAtom = atom(false);
export const streamMapErrorAtom = atom<Error | null>(null);
export const mapDataAtom = atom(INITIAL_INTRO_VALUE);

export const chapterAtom = atom<{
  topicIndex: number | null;
  subTopicIndex: number | null;
}>({
  topicIndex: null,
  subTopicIndex: null,
});

export const nextChapterAtom = atom((get) => {
  const { topicIndex, subTopicIndex } = get(chapterAtom);
  const isStreaming = get(isStreamingContentAtom) || get(isStreamingMapAtom);
  const mapData = get(mapDataAtom);

    console.log('🚀 ~ nextChapterAtom ~ topicIndex:', topicIndex);
    console.log('🚀 ~ nextChapterAtom ~ subTopicIndex:', subTopicIndex);
    console.log('🚀 ~ nextChapterAtom ~ mapData:', mapData);

  if (topicIndex === null || subTopicIndex === null) {
    // return {
    //   nextTopicIndex: 0,
    //   nextSubTopicIndex: 0,
    //   nextSubtopicTitle: mapData.topicList[0].subTopics[0].subTopicName,
    //   nextSubtopicSlug: mapData.topicList[0].subTopics[0].slug,
    // }
    return null;
  }



  const currentTopic = mapData.topicList[topicIndex];
  if (!currentTopic) {
    return null;
  }

  if (subTopicIndex < currentTopic.subTopics.length - 1) {
    // If there are more subtopics in the current topic
    return {
      nextTopicIndex: topicIndex,
      nextSubTopicIndex: subTopicIndex + 1,
      nextSubtopicTitle: currentTopic.subTopics[subTopicIndex + 1].subTopicName,
      nextSubtopicSlug: currentTopic.subTopics[subTopicIndex + 1].slug,
    };
  } else if (topicIndex < mapData.topicList.length - 1) {
    // If we're at the last subtopic of the current topic, move to the next topic
    return {
      nextTopicIndex: topicIndex + 1,
      nextSubTopicIndex: 0,
      nextSubtopicTitle:
        mapData.topicList[topicIndex + 1].subTopics[0].subTopicName,
      nextSubtopicSlug: mapData.topicList[topicIndex + 1].subTopics[0].slug,
    };
  } else {
    // If we're at the last subtopic of the last topic, return null
    return null;
  }
});

export const prevChapterAtom = atom((get) => {
  const { topicIndex, subTopicIndex } = get(chapterAtom);
  const isStreaming = get(isStreamingContentAtom) || get(isStreamingMapAtom);
  const mapData = get(mapDataAtom);

  if (topicIndex === null || subTopicIndex === null) {
    return null;
  }

  if (subTopicIndex > 0) {
    // If there are previous subtopics in the current topic
    return {
      prevTopicIndex: topicIndex,
      prevSubTopicIndex: subTopicIndex - 1,
      prevSubtopicTitle:
        mapData.topicList[topicIndex].subTopics[subTopicIndex - 1].subTopicName,
      prevSubtopicSlug:
        mapData.topicList[topicIndex].subTopics[subTopicIndex - 1].slug,
    };
  } else if (topicIndex > 0) {
    // If we're at the first subtopic of the current topic, move to the previous topic
    const prevTopic = mapData.topicList[topicIndex - 1];
    const prevSubTopicIndex = prevTopic.subTopics.length - 1;
    return {
      prevTopicIndex: topicIndex - 1,
      prevSubTopicIndex: prevSubTopicIndex,
      prevSubtopicTitle: prevTopic.subTopics[prevSubTopicIndex].subTopicName,
      prevSubtopicSlug: prevTopic.subTopics[prevSubTopicIndex].slug,
    };
  } else {
    // If we're at the first subtopic of the first topic, return null
    return null;
  }
});

export const isStreamingContentAtom = atom(false);
export const streamContentErrorAtom = atom<Error | null>(null);

export const contentAtom = atom(
  (get) => {
    const currentMap = get(mapDataAtom);
    if (!currentMap) {
      return '';
    }
    const { subTopicIndex, topicIndex } = get(chapterAtom);
    if (
      (subTopicIndex !== -1 && subTopicIndex !== null) &&
      (topicIndex !== -1 && topicIndex !== null)
    ) {

      return currentMap.topicList[topicIndex].subTopics[subTopicIndex]
        .content;
    } else {
      return currentMap.jobDescription || '';
    }
  },
  (_get, set, newText) => {
    console.log("🚀 ~ newText:", newText)
    const currentMap = _get(mapDataAtom);
    console.log("🚀 ~ currentMap:", currentMap)
    
    const { subTopicIndex, topicIndex } = _get(chapterAtom);
    if (!currentMap) {
      return;
    }

    // update the content of the subtopic

    if (subTopicIndex !== null && topicIndex !== null) {
      const updatedSubtopics = [
        ...currentMap.topicList[topicIndex].subTopics,
      ];
      updatedSubtopics[subTopicIndex].content = newText;
      const updatedTopicList = [...currentMap.topicList];
      updatedTopicList[topicIndex].subTopics = updatedSubtopics;
      set(mapDataAtom, { ...currentMap, topicList: updatedTopicList });
    } else {
      set(mapDataAtom, { ...currentMap, jobDescription: newText });
    }
  }
);

export const mobileSidebar = atom(false);
export const desktopSidebar = atom(true);
export const mapViewAtom = atom(false);

export const chaptersOpenStatesAtom = atom<boolean[]>([false]);

// DERIVED ATOMS
export const isEitherStreamingAtom = atom((get) => {
  return get(isStreamingContentAtom) || get(isStreamingMapAtom);
});

// Add this near the end of the file, with the other derived atoms
export const applicationStateAtom = atom((get) => {
  return {
    // Map related
    isStreamingMap: get(isStreamingMapAtom),
    streamMapError: get(streamMapErrorAtom),
    mapData: get(mapDataAtom),
    
    // Chapter related
    currentChapter: get(chapterAtom),
    nextChapter: get(nextChapterAtom),
    prevChapter: get(prevChapterAtom),
    chaptersOpenStates: get(chaptersOpenStatesAtom),
    
    // Content related
    isStreamingContent: get(isStreamingContentAtom),
    streamContentError: get(streamContentErrorAtom),
    content: get(contentAtom),
    
    // UI State
    mobileSidebar: get(mobileSidebar),
    desktopSidebar: get(desktopSidebar),
    mapView: get(mapViewAtom),
    
    // Derived states
    isEitherStreaming: get(isEitherStreamingAtom),
  };
});






// OLD STUFF
// export const isStreamingAtom = atom(false);

// export const contentDataAtom = atom(INITIAL_CONTENT_VALUE);
// export const contentIsStreamingAtom = atom(false);

// these need to be different because they have different default values

// New atom for storing topicIndex and subTopicIndex

// useHydrateAtoms([
//   [mobileSidebar, false],
//   [desktopSidebar, true],
// ]);
// const [showMobileSidebar, setShowMobileSidebar] = useAtom(mobileSidebar);
// const [showDesktopSidebar, setShowDesktopSidebar] = useAtom(desktopSidebar);

