import React, { useEffect, useState } from 'react';
import { ChevronUpDownIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { FaMap, FaProjectDiagram, FaRegShareSquare } from 'react-icons/fa';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import { Fragment } from 'react';
import { Dialog, Popover, Portal, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  BookmarkSquareIcon,
  CalendarDaysIcon,
  LifebuoyIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';

import { createClient } from '../../../lib/supabase/client';
import LoadingOverlay from '../../../shared/LoadingOverlay';
import { toast } from 'react-toastify';
import { getShareLink } from '../../../lib/maps';
import getMixPanelClient from '../../../lib/mixpanelBrowserClient';
import camelcaseKeys from 'camelcase-keys';
import { CareerMap } from '../../../types';
import { PostgrestError, SupabaseClient, User } from '@supabase/supabase-js';
// const mixpanelClient = getMixPanelClient();

const convertPostgresDate = (date) => {
  const options = {
    month: 'long' as const,
    day: 'numeric' as const,
    year: 'numeric' as const,
  };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  return formattedDate;
};

const EmptyState: React.FC<{ user: User | null }> = ({ user }) => {
  return (
    <div className="text-center">
      <FaMap className="mx-auto h-12 w-12 text-colors-green" />
      <h3 className="mt-2 text-sm font-semibold text-colors-green">
        No Saved Maps
      </h3>
      {!user ? (
        <>
          <p className="mt-1 text-sm text-shades-text/95">
            you must be logged in to save maps.
          </p>
          <div className="mt-6">
            <Link
              className="inline-flex items-center rounded-md bg-colors-green hover:bg-colors-greenDark px-3 py-2 text-sm font-semibold text-shades-base shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              href={'/login'}
            >
              {/* <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" /> */}
              Login
            </Link>
          </div>
        </>
      ) : (
        <p className="mt-1 text-sm text-gray-500">
          You have not created any maps yet.
        </p>
      )}
    </div>
  );
};

const fetchUserMapsFromDbByUserID = async (
  user: User
): Promise<{ data: CareerMap[] | null; error: PostgrestError | null }> => {
  const SupabaseClient = createClient();

  //fetch all maps that match the user's id
  const { data, error } = await SupabaseClient.from('maps')
    .select('*')
    .eq('user_id', user.id)
    // .eq('user_id', '4caa6381-3d2d-4c57-8587-5e6979d95165')
    .order('updated_at', { ascending: false });
  if (error) {
    return { data: null, error };
  }
  return { data: camelcaseKeys(data), error };
};

/*
..######..########.....##.....##.########.##....##.##.....##
.##....##.##.....##....###...###.##.......###...##.##.....##
.##.......##.....##....####.####.##.......####..##.##.....##
..######..########.....##.###.##.######...##.##.##.##.....##
.......##.##.....##....##.....##.##.......##..####.##.....##
.##....##.##.....##....##.....##.##.......##...###.##.....##
..######..########.....##.....##.########.##....##..#######.
*/

export const SideBarUserMenu: React.FC<{
  user: User | null;
  // jobTitle: string;
}> = ({ user }) => {
  useHydrateAtoms([[mapDataAtom, '']]); // todo: is this needed? (with data that is not from the server at anypoint?)
  const [streamMapData, setMapIntro] = useAtom(mapDataAtom);

  const NodesMemoized = React.useMemo(() => {
    return (
      <div className="flex gap-4 flex-col">
        <SideBarUserMenuUnmemoized
          user={user}
          jobTitle={streamMapData?.jobTitle}
        />
      </div>
    );
  }, [streamMapData?.jobTitle, user]);

  return NodesMemoized;
};

export const SideBarUserMenuUnmemoized: React.FC<{
  user: User | null;
  jobTitle: string;
}> = ({ user, jobTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [savedMaps, setSavedMaps] = useState<CareerMap[] | null>(null);
  const SupabaseClient = createClient();
  const [showUserMenu, setShowUserMenu] = useState(false);

  React.useEffect(() => {
    const fetchUserMaps = async () => {
      if (user) {
        setIsLoading(true);
        const { data, error } = await fetchUserMapsFromDbByUserID(user);
        if (error) {
          toast.error('Error fetching user maps. Please try again.', {
            toastId: 'FETCH_USER_MAPS_ERROR',
            theme: 'dark',
          });
        } else {
          setSavedMaps(data);
        }
        setIsLoading(false);
      }
    };
    fetchUserMaps();
  }, [user, SupabaseClient]);

  return (
    <>
      <Popover className="">
      <div className="px-1">
          <Popover.Button
            className="flex justify-between p-2.5 px-4 bg-shades-baseLight border-[1px] border-white/25 hover:brightness-110 text-shades-text/90 rounded-lg cursor-pointer w-full relative"
            onClick={() => {
              setShowUserMenu(true);
            }}
            aria-haspopup="true"
            aria-label="User Menu"
            aria-controls="userMenu"
          >
            {/* <FolderIcon
            className="h-9 w-9 shrink-0 text-colors-green -mr-11"
            aria-hidden="true"
            /> */}
            <div className="flex flex-col text-left relative top-[2px]">
              <p className="text-xs font-bold leading-[11px]">Career Map:</p>
              <p className="font-thin text-shades-text/95 mt-[1px]">
                {jobTitle || ''}
              </p>
            </div>
            <ChevronUpDownIcon
              className="h-5 w-5 shrink-0 relative left-1.5"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>

        {showUserMenu && (
          <>
            <Portal>
              <Popover.Overlay
                as="div"
                className="w-[100vw] h-[100vh] z-50 top-0 left-0 fixed"
              />
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  id="userMenu"
                  className={
                    'z-[100] mt-9 max-w-[300px] flex px-4 fixed top-[50px] lg:absolute lg:top-[112px] left-0'
                  }
                >
                  <div
                    // zIndex="popover"
                    className="flex flex-col w-screen max-w-80 lg:max-w-md flex-auto overflow-hidden rounded-lg bg-shades-baseLighter backdrop-blur-md text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 max-h-[calc(100vh-100px)] relative right-[1px]"
                  >
                    <div className="h-full overflow-y-auto scrollbar bg-transparent scrollbar-thumb-colors-green/50 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-track-colors-green/20 scrollbar-w-2 shadow-[inset_-2px_-2px_4px_rgba(0,0,0,0.10)]">
                      <>
                        {/* {isLoading ||
                          (savedMaps == null && (
                            <LoadingOverlay text="Loading Maps..." />
                          ))} */}

                        {!user && (
                          <div className="py-5">
                            <EmptyState user={user} />
                          </div>
                        )}

                        {!!savedMaps?.length && savedMaps.length > 0 && (
                          <div className="p-4 pt-1 ">
                            <h3 className="text-shades-text mt-2 mb-1 text-xs font-semibold leading-6 relative">
                              MY MAPS <span className="text-xs mr-px">(</span>
                              <span className="text-sm relative top-px">{savedMaps.length}</span>
                              <span className="text-xs ml-px">)</span>
                            </h3>
                            <ul role="list" className="space-y-2">
                              {savedMaps.toReversed().map((currMap) => (
                                <li
                                  key={currMap.id}
                                  className="relative group transition-all duration-300  hover:bg-colors-green rounded-lg p-2 px-3"
                                >
                                  <Link
                                    href={`/map/${currMap.slug}/overview`}
                                    onClick={() => {
                                      setShowUserMenu(false);
                                    }}
                                  >
                                    {/* <time
                                      dateTime={currMap.createdAt}
                                      className="block text-xs leading-6 text-gray-600"
                                    >
                                      {convertPostgresDate(currMap.createdAt)}
                                    </time> */}

                                    <p className="block truncate transition-all text-sm leading-6  group-hover:text-shades-baseLight text-shades-text/90">
                                      {currMap.jobTitle}
                                      <span className="absolute inset-0" />
                                    </p>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </>
                    </div>
                    {user && (
                      <div className="px-4 py-2 flex justify-end z-20">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-cream hover:underline"
                          onClick={async () => {
                            setIsLoading(true);
                            const { error } =
                              await SupabaseClient.auth.signOut();
                              window.location.reload();
                            // mixpanelClient.track('logout');
                            if (!error) {
                              // reload page to reset state
                              // mixpanelClient.reset();
                              // window.location.reload();
                            } else {
                              toast.error(
                                'Error logging out. Please try again.',
                                {
                                  toastId: 'LOGOUT_ERROR',
                                  theme: 'dark',
                                }
                              );
                              setIsLoading(false);
                            }
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </Portal>
          </>
        )}
      </Popover>
    </>
  );
};

/*
.##.....##..######..########.########..##.....##.########.##....##.##.....##
.##.....##.##....##.##.......##.....##.###...###.##.......###...##.##.....##
.##.....##.##.......##.......##.....##.####.####.##.......####..##.##.....##
.##.....##..######..######...########..##.###.##.######...##.##.##.##.....##
.##.....##.......##.##.......##...##...##.....##.##.......##..####.##.....##
.##.....##.##....##.##.......##....##..##.....##.##.......##...###.##.....##
..#######...######..########.##.....##.##.....##.########.##....##..#######.
*/
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import { mapDataAtom } from '../../../jotaiStore';
import { useHydrateAtoms } from 'jotai/utils';
import { useAtom } from 'jotai';


const UserMenu: React.FC<{
  user: User | null;
  showUserMenu: boolean;
  setShowUserMenu: (show: boolean) => void;
  children: React.ReactNode;
  panelStyles: string;
}> = ({ user, showUserMenu, setShowUserMenu, children, panelStyles }) => {
  const getUsersQuery = useSuspenseQuery(queryOptions({
    queryKey: ['users'],
    queryFn: async () => {
      if (user) {
        const test = await fetchUserMapsFromDbByUserID(user);
        return test;
      }
    }
  }))

  const [isLoading, setIsLoading] = useState(false);
  const SupabaseClient = createClient();

  // React.useEffect(() => {
  //   const fetchUserMaps = async () => {
  //     if (user) {
  //       setIsLoading(true);
  //       const { data, error } = await fetchUserMapsFromDbByUserID(user);
  //       if (error) {
  //         toast.error('Error fetching user maps. Please try again.', {
  //           toastId: 'FETCH_USER_MAPS_ERROR',
  //           theme: 'dark',
  //         });
  //       } else {
  //         setSavedMaps(data);
  //       }
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchUserMaps();
  // }, [user]);

  const savedMaps = getUsersQuery.data?.data

  return (
    <>
      <Popover className="">
        {children}

        {showUserMenu && (
          <>
            <Portal>
              <Popover.Overlay
                as="div"
                className="w-[100vw] h-[100vh] z-50 top-0 left-0 fixed"
              />
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  id="userMenu"
                  className={
                    'absolute left-full z-[100] mt-[35px] flex w-screen max-w-max -translate-x-full top-[35px] px-4'
                  }
                >
                  <div
                    // zIndex="popover"
                    className="flex flex-col w-screen max-w-80 lg:max-w-md flex-auto overflow-hidden rounded-lg bg-shades-baseLightest backdrop-blur-md text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 max-h-[calc(100vh-100px)]"
                  >
                    {user && (
                      <div className="bg-transparent z-20">
                        <p className="text-shades-text text-md font-semibold shadow-md p-4">
                          Logged in as:{' '}
                          <span className="text-colors-green">
                            {user.email}
                          </span>
                        </p>
                      </div>
                    )}

                    <div className="h-full overflow-y-auto scrollbar bg-transparent scrollbar-thumb-shades-base scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-track-shades-baseLightest scrollbar-w-2 shadow-[inset_-2px_-2px_4px_rgba(0,0,0,0.10)]">
                      <>
                        {isLoading ||
                          (savedMaps == null && (
                            <LoadingOverlay text="Loading Maps..." />
                          ))}
                        {!savedMaps ||
                          (savedMaps.length === 0 && (
                            <div className="py-5">
                              <EmptyState user={user} />
                            </div>
                          ))}
                        {!!savedMaps?.length && savedMaps.length > 0 && (
                          <div className="p-4 pt-4 ">
                            <div className="flex justify-between">
                              <h3 className="text-sm font-semibold leading-6 text-shades-text relative">
                                YOUR MAPS <span className="text-xs mr-px">(</span>
                                {savedMaps.length}
                                <span className="text-xs mx-px">)</span>
                                
                              </h3>
                            </div>
                            <ul role="list" className="mt-2 space-y-2">
                              {savedMaps.toReversed().map((currMap) => (
                                <li
                                  key={currMap.id}
                                  className="relative group "
                                >
                                  <Link
                                    href={`/map/${currMap.slug}/overview`}
                                    onClick={() => {
                                      setShowUserMenu(false);
                                    }}
                                    className="hover:bg-colors-green hover:text-shades-baseLight text-shades-text/75 rounded-lg p-2 flex flex-col px-4"
                                  >
                                    <time
                                      dateTime={currMap.createdAt}
                                      className="block text-xs "
                                    >
                                      {convertPostgresDate(currMap.createdAt)}
                                    </time>

                                    <p className="block truncate text-sm hover:text-shades-base text-shades-text font-semibold leading-6">
                                      {currMap.jobTitle}
                                      <span className="absolute inset-0" />
                                    </p>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </>
                    </div>
                    {user && (
                      <div className="p-4 flex justify-end z-20">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-shades-text hover:underline"
                          onClick={async () => {
                            setIsLoading(true);
                            const { error } =
                              await SupabaseClient.auth.signOut();
                              window.location.reload();

                            // mixpanelClient.track('logout');
                            if (!error) {
                              // reload page to reset state
                              // mixpanelClient.reset();
                              // window.location.reload();
                            } else {
                              toast.error(
                                'Error logging out. Please try again.',
                                {
                                  toastId: 'LOGOUT_ERROR',
                                  theme: 'dark',
                                }
                              );
                              setIsLoading(false);
                            }
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
              {/* </Dialog> */}
            </Portal>
          </>
        )}
      </Popover>
    </>
  );
};

export default UserMenu;
