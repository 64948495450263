import React from 'react';

const LoadingOverlay = ({ text }) => {
  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    //   <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
    // </div>
    <div className="fixed flex flex-col justify-center items-center min-h-screen bg-black/40 z-100 inset-0">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-colors-green -mt-32"></div>
      <p className="text-2xl mt-5 text-colors-green">{text || 'Loading...'}</p>
      {text === 'Generating Map' && (
        <p className="relative left-[2px] text-colors-green/60">
          (this may take up to a min)
        </p>
      )}
    </div>
  );
};

export default LoadingOverlay;
